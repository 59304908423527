import React, {useContext} from 'react';
import LangContext from '../components/context/langContext';
import EventsContent from '../components/events/eventsContent';
import Layout from '../components/template/layout';
import { GetEventsSeoEN } from '../content/pages/actions/events/seo/en/GetEventsSeo';
import { GetEventsSeoFR } from '../content/pages/actions/events/seo/fr/GetEventsSeo';
    
    const Events = () => {
        const ctx = useContext(LangContext)
        const content = ctx.lang === 'fr' ? GetEventsSeoFR() : GetEventsSeoEN()
        return (
            <Layout
            title={content.pageTitle}
            description={content.pageDescription}
            lang={ctx.lang}
            page="events"
        >
            <div className="content-container">
                <EventsContent
                    lang={ctx.lang}
                />
            </div>
            </Layout>
        );
    };
    
    export default Events;