import {useStaticQuery, graphql} from 'gatsby';


export const GetEventsSeoEN = () =>  {
	
	const content = useStaticQuery(graphql`
	query EventsSeoEN {
		allMarkdownRemark(
		  filter: {frontmatter: {lang: {eq: "en"}}, fields: {collection: {eq: "EventsSeo"}}}
		) {
		    edges {
                node {
                    frontmatter{
                       pageTitle
                       pageDescription
                    }
                }
		    }
        }
	}
`);

	return content.allMarkdownRemark.edges[0].node.frontmatter;
};