import React from 'react';
import { GetEventsEN } from '../../content/pages/actions/events/eventsBlock/en/GetEvents';
import { GetEventsFR } from '../../content/pages/actions/events/eventsBlock/fr/GetEvents';
import './events.scss';

const EventsContent = ({lang}) => {
    const content = lang === 'fr' ? GetEventsFR() : GetEventsEN()
    return (
        <div className="events">
            <h3   
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease">{content.eventsTitle}</h3>
            {content.eventsList.map((e,i) => (
                <div>
                    <p className="date" key={i}>{e.date}</p>
                    <p> 
                        {e.content}
                    </p>
                </div>
            ))
            }
        </div>
    );
};

export default EventsContent;